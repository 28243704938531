import { render, staticRenderFns } from "./HowItWorks.vue?vue&type=template&id=55a49957&scoped=true&"
import script from "./HowItWorks.vue?vue&type=script&lang=js&"
export * from "./HowItWorks.vue?vue&type=script&lang=js&"
import style0 from "./HowItWorks.vue?vue&type=style&index=0&id=55a49957&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a49957",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VImg,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55a49957')) {
      api.createRecord('55a49957', component.options)
    } else {
      api.reload('55a49957', component.options)
    }
    module.hot.accept("./HowItWorks.vue?vue&type=template&id=55a49957&scoped=true&", function () {
      api.rerender('55a49957', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/HowItWorks.vue"
export default component.exports