<template>
  <section id="hiw">
    <v-container>
      <v-row>
        <v-col class="col-md-11 mx-auto">
          <div class="d-flex justify-center">
            <h2 class="title-section header-title text-capitalize">
              {{ $t("HowItWorks") }}
            </h2>
          </div>
          <v-row class="justify-space-around">
            <v-col class="col-md-4 mx-auto">
              <v-img
                :width="$vuetify.breakpoint.smAndUp ? '320px' : '280px'"
                class="mx-auto"
                :src="require(`../assets/images/howitworks/select-service.webp`)"
              ></v-img>
              <p class="text-center mt-5 hiw-card-text">
                {{ $t("HowItWorksSection.SelectYourService") }}
              </p>
            </v-col>
            <v-col class="col-md-4 mx-auto">
              <v-img
                :width="$vuetify.breakpoint.smAndUp ? '320px' : '280px'"
                class="mx-auto"
                :src="require(`../assets/images/howitworks/get-estimate.webp`)"
              ></v-img>
              <p class="text-center hiw-card-text">{{ $t("GetAnEstimate") }}</p>
            </v-col>
            <v-col class="col-md-4">
              <v-img
                :width="$vuetify.breakpoint.smAndUp ? '320px' : '280px'"
                class="mx-auto"
                :src="require(`../assets/images/howitworks/schedule-appointment.webp`)"
              ></v-img>
              <p class="text-center hiw-card-text">
                {{ $t("HowItWorksSection.ScheduleAppointment") }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <p class="information info-text">
        {{ $t("ComeToYourLocation") }}
      </p>
    </v-container>
  </section>
</template>
<style scoped lang="scss">
@import "../assets/styles/sass/components/hiw";
</style>
<script>
export default {
  name: "HowItWorks",
};
</script>
