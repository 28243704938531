var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "hiw" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "col-md-11 mx-auto" },
                [
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "title-section header-title text-capitalize",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("HowItWorks")) + " ")]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "justify-space-around" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-md-4 mx-auto" },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              width: _vm.$vuetify.breakpoint.smAndUp
                                ? "320px"
                                : "280px",
                              src: require("../assets/images/howitworks/select-service.webp"),
                            },
                          }),
                          _c(
                            "p",
                            { staticClass: "text-center mt-5 hiw-card-text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "HowItWorksSection.SelectYourService"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "col-md-4 mx-auto" },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              width: _vm.$vuetify.breakpoint.smAndUp
                                ? "320px"
                                : "280px",
                              src: require("../assets/images/howitworks/get-estimate.webp"),
                            },
                          }),
                          _c(
                            "p",
                            { staticClass: "text-center hiw-card-text" },
                            [_vm._v(_vm._s(_vm.$t("GetAnEstimate")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "col-md-4" },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              width: _vm.$vuetify.breakpoint.smAndUp
                                ? "320px"
                                : "280px",
                              src: require("../assets/images/howitworks/schedule-appointment.webp"),
                            },
                          }),
                          _c(
                            "p",
                            { staticClass: "text-center hiw-card-text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "HowItWorksSection.ScheduleAppointment"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", { staticClass: "information info-text" }, [
            _vm._v(" " + _vm._s(_vm.$t("ComeToYourLocation")) + " "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }